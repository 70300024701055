import React from 'react'
import './styles.css'

const ErrorPage = () => {
  return (
    <div className='error-404'>
      <h1>404</h1>
      <h2>Esta página no existe</h2>
    </div>
  )
}

export default ErrorPage